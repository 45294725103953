<template>
  <el-dialog title="上传打款凭证" width="700px"
             v-model="dialogShow"
             @close="$emit('close', '')">
    <!-- 内容主体区域 -->
    <el-row v-loading="$store.getters.isLoading">
      <el-col :span="22">
        <el-form ref="formRef" :rules="rules" :model="form" label-width="180px">
          <el-form-item label="企业名称" required>
            <el-input size="small" disabled v-model="$store.getters.userInfo.company"/>
          </el-form-item>
          <el-form-item label="收款账户" prop="type">
            <el-select size="small" :fit-input-width="true" v-model="form.type" placeholder="请选择收款账户">
              <el-option label="香港汇丰-838-741-206890-838" value="香港汇丰-838"></el-option>
              <el-option label="花旗银行-9007-1020039007" value="花旗银行-9007"></el-option>
              <el-option label="万里汇-accounting@yinolink.com" value="万里汇"></el-option>
              <el-option label="pingpong-YinoLink易诺" value="pingpong"></el-option>
              <el-option label="lianlian-YinoLink易诺" value="Lianlian"></el-option>
              <el-option label="payoneer-accounting@yinolink.com" value="payoneer"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="打款金额" prop="money">
            <el-input
                size="small"
                clearable
                prefix-icon="iconfont icon-meiyuan"
                v-model="form.money"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input size="small" clearable v-model="form.mark"></el-input>
          </el-form-item>
          <el-form-item prop="path">
            <template #label>
              打款凭证<br/>
              <span class="LinkText clickImage">点击下方示例图</span>
              <el-image style="width: 100px; height: 100px"
                        src="/images/finance/pingpong.jpeg"
                        :preview-src-list="previewSrcList">
              </el-image>
            </template>
            <el-upload
                :action="`${base.admin_url}/cloud/file/uploadfile`"
                :limit="1"
                accept=".jpeg,.png,.jpg"
                list-type="picture-card"
                :headers="{Authorization: $store.getters.userInfo.token}"
                :on-preview="handlePictureCardPreview"
                :on-remove="() => form.path = ''"
                :on-success="response => form.path = response.path"
                :before-upload="beforeImgUpload"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                <em class="LinkText">点击上传打款凭证</em><br/>
                支持jpeg、png、jpg格式
              </div>
            </el-upload>
            <el-dialog v-model="imgDialogShow">
              <img style="width: 100%;" :src="previewImg" alt=""/>
            </el-dialog>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submit">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
import base from "@/request/base";
import {ElMessage} from "element-plus";
import api from "@/request/api/finance";

const {proxy} = getCurrentInstance()
const dialogShow = ref(true)
const formRef = ref(null)
const previewSrcList = [
  '/images/finance/pingpong.jpeg',
  '/images/finance/kongzhongyunhui.jpeg',
  '/images/finance/lianlian.png'
]
const form = ref({
  company: '',
  money: '',
  mark: '',
  path: ''
})
const rules = {
  type: [{required: true, message: '请选择收款账户', trigger: 'change'}],
  money: [
    {required: true, message: '请输入打款金额', trigger: 'blur'},
    {
      pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
      message: '充值金额错误，请重新输入',
      trigger: 'blur'
    }
  ],
  path: [{required: true, message: '请上传打款凭证'}]
}

const imgDialogShow = ref(false)
const previewImg = ref('')

const handlePictureCardPreview = file => {
  previewImg.value = file.response.path1
  imgDialogShow.value = true
}

const beforeImgUpload = file => {
  let img = file.name.substring(file.name.lastIndexOf('.') + 1).toUpperCase()

  if (['JPG', 'PNG', 'JPEG'].indexOf(img) < 0) {
    ElMessage.error('仅支持发送图片！')
    return false
  }

  return true
}

const submit = async () => {
  const result = await formRef.value.validate()
  if (true !== result) return
  console.log(form.value)
  const res = await api.submitVoucher(form.value)
  if (res.code == 200) proxy.$router.push('/recharge_list')
}
</script>

<style scoped lang="less">
:deep(.el-upload) {
  width: 100% !important;
  height: 100% !important;
}

:deep(.el-upload .el-upload__text) {
  position: relative;
  bottom: 33px;
  line-height: 1.5;
}
</style>