<template>
  <el-dialog v-model="dialogShow" :title="content.title ? content.title : '标题'"
             :width="'alert' === contentType ? '440px' : '700px'" @close="$emit('close', '')"
             :custom-class="contentType">
    <el-row v-if="'alert' === contentType" align="middle">
      <el-col :span="2">
        <i class="el-icon-warning" style="color: var(--el-color-warning);font-size: 24px;"/>
      </el-col>
      <el-col class="alert-content" :span="22" v-if="content.content" v-html="content.content"/>
    </el-row>
    <el-form :model="form" label-width="auto" :class="paymentData.type === 'Payoneer' ? 'payoneer-form' : ''" v-else>
      <el-form-item label="付款金额" required>
        <el-input size="small" prefix-icon="iconfont icon-meiyuan" disabled v-model="confirmData.dollar"/>
      </el-form-item>
      <el-form-item label="手续费" required>
        <el-input v-if="paymentData.type !== '空中云汇'" size="small" disabled
                  v-model="confirmData.handlingFee"></el-input>
        <el-input v-else size="small" disabled v-model="confirmData.handlingFee"></el-input>
      </el-form-item>
      <el-form-item label="" required v-if="paymentData.type !== '空中云汇' && paymentData.type !== 'Payoneer'">
        <template #label>
          <span v-if="paymentData.type == '连连支付'">应付美元</span>
          <span v-else>应付人民币</span>
        </template>
        <el-input size="small"
                  :prefix-icon="paymentData.type == '连连支付' ? 'iconfont icon-meiyuan' : 'iconfont icon-renminbi'"
                  disabled
                  v-model="confirmData.amount"></el-input>
      </el-form-item>
      <el-form-item label="实际到账" required>
        <el-input size="small" prefix-icon="iconfont icon-meiyuan" disabled
                  v-model="confirmData.totalAmount"></el-input>
      </el-form-item>
      <el-form-item label="Payoneer余额" required v-if="paymentData.type === 'Payoneer'">
        <el-input size="small" prefix-icon="iconfont icon-meiyuan" disabled v-model="confirmData.balance"></el-input>
        <el-popover placement="top" width="auto" trigger="hover" content="切换账户"
                    :offset="5"
                    :hide-after="0"
                    @after-enter="switchImg('enter')" @after-leave="switchImg('leave')">
          <template #reference>
            <el-button class="switch-user-btn" type="text" @click="switchPayonnerUser('btn')">
              <img :width="16" src="@/assets/images/recharge/payoneer-user-switch-selected.png"
                   v-if="payOneerSwitchSelected"/>
              <img :width="16" src="@/assets/images/recharge/payoneer-user-switch.png" v-else/>
            </el-button>
          </template>
        </el-popover>
      </el-form-item>
    </el-form>
    <template #footer>
      <template v-if="'alert' === contentType">
        <el-button size="small" @click="dialogShow = false">
          {{ content.cancelText ? content.cancelText : '取 消' }}
        </el-button>
        <el-button size="small" type="primary" @click="content.handleSuccess ? content.handleSuccess() : ''">
          {{ content.confirmText ? content.confirmText : '确 定' }}
        </el-button>
      </template>
      <template v-else>
        <el-button size="small" @click="dialogShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submit">确 定</el-button>
      </template>
    </template>
  </el-dialog>
</template>

<script setup>
import {getCurrentInstance, ref, toRaw} from "vue";
import init from '@/views/finance/recharge/base'
import {loadAirwallex} from 'airwallex-payment-elements'
import api from '@/request/api/finance'
import {ElLoading} from 'element-plus'

const dialogShow = ref(true)
const props = defineProps({
  paymentData: {type: Object},
  confirmData: {type: Object},
  dialogType: {default: ''},
  dialogContent: {type: Object}
})

const {refreshStatus} = init()

const {proxy} = getCurrentInstance()
const form = ref({
  type: props.type,
})

const initContent = () => {
  return {
    title: '充值',
    content: '<div>请在新开页面进行充值</div><div>充值完成后，根据您的情况点击按钮</div>',
    confirmText: '充值成功',
    cancelText: '充值失败',
    handleSuccess: async () => {
      await refreshStatus(props.confirmData.rechargeId)
      dialogShow.value = false
    }
  }
}

const payOneerSwitchSelected = ref(false)
const switchImg = action => {
  payOneerSwitchSelected.value = action === 'enter'
}

const contentType = ref(props.dialogType ? props.dialogType : '')
const content = ref(Object.assign(initContent(), props.dialogContent ? props.dialogContent : {}))

const submit = async () => {
  if (['支付宝1', '微信'].indexOf(props.paymentData.type) >= 0) {
    return proxy.$router.push({
      name: 'payment',
      query: {
        payid: props.confirmData.payId
      }
    })
  }

  if (props.paymentData.type === '空中云汇') {
    const airwallexParams = Object.assign({
      env: process.env.NODE_ENV === 'production' ? 'prod' : 'demo',
      successUrl: `${window.location.origin}/recharge_list`,
      cancelUrl: `${window.location.origin}/recharge`,
      failUrl: `${window.location.origin}/recharge`
    }, toRaw(props.confirmData.airwallex))
    const airwallexApi = await loadAirwallex(airwallexParams)
    console.log(airwallexApi.redirectToCheckout(Object.assign(airwallexParams, {disableAutoRedirect: true})));
    window.open(airwallexApi.redirectToCheckout(Object.assign(airwallexParams, {disableAutoRedirect: true})), '_blank')
  }

  if (props.paymentData.type === 'Payoneer') {
    if (parseFloat(props.confirmData.dollar) > parseFloat(props.confirmData.balance)) {
      return proxy.$message.error('Payoneer余额不足')
    }
    const loading = ElLoading.service({fullscreen: true})
    try {
      const res = await api.submitPayoneer(props.confirmData.recharge_no)
      if (res.code === 200 && res.type !== 1) {
        return proxy.$message({
          message: res.msg,
          type: 'success',
          duration: 3000,
          onClose: () => {
            proxy.$router.push('/recharge_list')
          }
        })
      } else {
        return window.location.href = res.url
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.close()
    }
  }

  contentType.value = 'alert'
  content.value = initContent()
  if (props.paymentData.type === '空中云汇') return
  window.open(props.confirmData.url)
}

const switchPayonnerUser = async () => {
  let loading = ElLoading.service({
    spinner: 'el-icon-loading',
    lock: true,
    text: '请稍后...'
  })
  
  let res = await api.judgePayoneerAuth({type: 3, dollar: props.confirmData.dollar}).catch(() => loading.close())
  if (res.url) {
    window.location.href = res.url
    loading.close()
  }
}
</script>

<style>
.el-dialog.alert .el-dialog__body {
  padding: 10px 20px !important;
}

.alert-content {
  line-height: 24px;
  font-size: 14px;
}

.payoneer-form {
  padding-right: 32px;
}

.payoneer-form .switch-user-btn {
  position: absolute;
  right: -32px;
}
</style>