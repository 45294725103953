<template>
  <el-dialog title="第三方委托付款" width="600px"
             v-model="dialogShow"
             @close="$emit('close', '')">
    <el-row v-loading="$store.getters.isLoading" justify="center">
      <el-col :span="22" v-if="form">
        <el-alert v-if="undefined !== form.status"
                  class="status-info"
                  :type="alertType"
                  :title="alertTitle"
                  :closable="false"
                  show-icon/>
        <el-form ref="formRef" :rules="rules" :model="form"
                 label-position="left"
                 label-width="150px">
          <el-form-item label="第三方委托付款协议" prop="contract_pay" class="upload-img-item">
            <template #label>
              第三方委托付款协议
              <el-row class="help-info" v-if="editable">
                请下载原件并填写信息、签署盖章后，上传扫描件
                <el-link type="primary" :underline="false"
                         @click="downloadFile('委托付款三方协议.docx')">下载协议原件
                </el-link>
              </el-row>
            </template>
            <el-upload
                v-if="editable"
                drag
                accept=".jpeg,.png,.jpg,.pdf"
                :file-list="contractPay"
                :action="`${base.admin_url}/cloud/file/uploadfile_three`"
                :limit="1"
                :headers="{Authorization: $store.getters.userInfo.token}"
                :on-success="(event) => form.contract_pay = event.path"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                <em class="LinkText"></em><br/>
                支持jpeg、png、jpg、pdf格式
              </div>
            </el-upload>
            <template v-else>
              <el-link v-if="form.contract_pay.indexOf('.pdf?') >= 0"
                       @click="filters.gotoPage(form.contract_pay)"
                       type="primary"
                       :underline="false">点击查看文件
              </el-link>
              <upload-file
                  v-else
                  :editable="editable"
                  :url="form.contract_pay"
                  :show-example="false"
              />
            </template>
          </el-form-item>
          <el-form-item label="打款手续费承担" prop="pay_fee">
            <el-radio-group v-model="form.pay_fee" :disabled="!editable">
              <el-radio label="甲方全部承担">甲方全部承担</el-radio>
              <el-radio label="双方共同承担">双方共同承担</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="打款方" prop="pay_bank">
            <el-select size="small"
                       :fit-input-width="true"
                       v-model="form.pay_bank"
                       :disabled="!editable"
                       placeholder="打款方">
              <el-option
                  v-for="item in ['拉卡拉','payoneer','空中云汇','万里汇','lianlian','Pingpong','Paypal','银行账户','其他']"
                  :key="item"
                  :label="item"
                  :value="item"/>
            </el-select>
          </el-form-item>
          <el-form-item label="账户截图" prop="party_img" class="upload-img-item">
            <template #label>
              账户截图
              <el-row class="help-info" v-if="editable">
                请提供此账户能够正常进行支付的订单截图
                <el-link type="primary" :underline="false"
                         @click="downloadFile('第三方截图指引文档.docx')">点击查看截图指引文档
                </el-link>
              </el-row>
            </template>
            <upload-file
                ref="uploadPartyImgRef"
                upload-text="点击上传第三方委托付款协议扫描件"
                v-if="editable || form.party_img"
                :editable="editable"
                :action="`${base.admin_url}/cloud/file/uploadfile`"
                :limit="1"
                :url="form.party_img"
                :show-example="false"
                @handleSuccess="form.party_img = $event.path"
            />
          </el-form-item>
          <template v-if="'银行账户' == form.pay_bank">
            <el-form-item label="银行账户账号" prop="recharge_bank_account">
              <el-input size="small" v-model="form.recharge_bank_account"
                        :disabled="!editable"/>
            </el-form-item>
            <el-form-item label="银行账户名" prop="recharge_bank_name">
              <el-input size="small" v-model="form.recharge_bank_name"
                        :disabled="!editable"/>
            </el-form-item>
            <el-form-item label="银行账户Swift Code" prop="recharge_bank_swift">
              <el-input size="small" v-model="form.recharge_bank_swift"
                        :disabled="!editable"/>
            </el-form-item>
          </template>
        </el-form>
      </el-col>
    </el-row>
    <template #footer>
      <template v-if="!editable">
        <el-button size="small" type="primary" @click="dialogShow = false">关 闭</el-button>
      </template>
      <template v-else>
        <el-button size="small" @click="dialogShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submit">确 定</el-button>
      </template>
    </template>
  </el-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, ref} from "vue";
import base from "@/request/base";
import {ElMessage} from "element-plus";
import api from "@/request/api/finance";
import UploadFile from "@/components/UploadFile";
import filters from '@/base/filters/base'

const {proxy} = getCurrentInstance()
const dialogShow = ref(true)
const formRef = ref(null)

let contractPay = ref([])

const form = ref({
  contract_pay: '',
  pay_fee: '',
  pay_bank: '',
  party_img: '',
  recharge_bank_account: '',
  recharge_bank_name: '',
  recharge_bank_swift: '',
  status: undefined
})

const rules = {
  contract_pay: [{required: true, message: '请上传第三方委托付款协议', trigger: 'blur'}],
  pay_fee: [{required: true, message: '请选择打款手续承担方式', trigger: 'blur'}],
  pay_bank: [{required: true, message: '请选择打款方', trigger: 'blur'}],
  party_img: [{
    validator: (rule, value, cb) => {
      if (['拉卡拉', '其他', '银行账户'].indexOf(form.value.pay_bank) < 0) {
        if (!value) {
          cb(new Error('请上传打款账户截图'))
        } else {
          cb()
        }
      }
      cb()
    }, trigger: 'blur'
  }],
  recharge_bank_account: [{required: true, message: '请输入银行账户账号', trigger: 'blur'}],
  recharge_bank_name: [{required: true, message: '请输入银行账户名', trigger: 'blur'}],
  recharge_bank_swift: [{required: true, message: '请输入银行账户Swift Code', trigger: 'blur'}],
}

const editable = computed(() => {
  return [0, 1, '0', '1'].indexOf(form.value.status) < 0
})

const submit = async () => {
  const result = await formRef.value.validate()
  if (true !== result) return
  const res = await api.submitThree(form.value)
  if (res.code == 200) {
    ElMessage.success(res.msg)
    dialogShow.value = false
  }
}

const downloadFile = file => {
  window.open(`document/${file}`)
}

onMounted(async () => {
  const res = await api.getThree()
  form.value = Object.assign(form.value, res.data ? res.data : {})
  contractPay.value = form.value.contract_pay ? [form.value.contract_pay] : []
})

const alertType = computed(() => {
  if (form.value.status == 0) return 'warning'
  if (form.value.status == 1) return 'success'
  if (form.value.status == 2) return 'error'

})

const alertTitle = computed(() => {
  if (form.value.status == 0) return '审核中'
  if (form.value.status == 1) return '审核通过'
  if (form.value.status == 2) return '审核被驳回，请重新提交审核'
})

</script>

<style scoped lang="less">
:deep(.el-form-item.upload-img-item) {
  display: block !important;

  .el-form-item__label {
    line-height: 20px !important;
  }
}

:deep(.help-info) {
  font-size: 14px;
  color: #8c8c8c;
  padding-top: 5px;

  .el-link {
    padding-left: 5px;
  }
}

:deep(.el-upload),
:deep(.el-upload-dragger) {
  width: 300px !important;
  background-color: #fbfdff;
  height: 200px !important;

  .el-upload__text {
    line-height: 1.5;
  }

  em {
    font-style: normal;
  }

  .el-icon-upload {
    color: #8c939d;
  }
}

:deep(.el-upload--picture-card i) {
  font-size: 67px;
}
</style>
<style>
.el-alert.status-info {
  margin-bottom: 10px;
}

.el-dialog .el-alert.el-alert--warning.is-light.status-info {
  color: #e6a23c;
  background-color: #fdf6ec !important;
  border-color: #ffffff !important;
}

.el-dialog .el-alert.is-light.status-info .el-icon-warning {
  color: #e6a23c !important;
}

.el-dialog .el-alert.is-light.status-info .el-icon-success {
  color: #67c23a !important;
}

.el-dialog .el-alert.is-light.status-info .el-icon-error {
  color: #f56c6c !important;
}
</style>